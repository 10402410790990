import "./src/style.css";
// import "./src/player_files/bootstrap.css";
import "./src/player_files/bootstrap-scoped.css";
import "./src/player_files/talk-plugin.css";
import "./src/player_files/keyboard.css";

import "@riotjs/hot-reload";
import { mount } from "riot";

// window.tamkin_src_base = "https://cdn.tamkin.app";
// if (process.env.NODE_ENV === "production") {
  
// window.tamkin_src_base = "https://cdn.tamkin.app";
// }
 

import * as riot from 'riot'

//import App from './app.riot'
// import  './src/player_files/jquery-3.5.1.min.js'

 
 import prin from './src/principalApp.riot'
// import TaminSDK from './src/tamkin-sdk-only-player.riot'
 import SamplePage from './src/sample-page.riot'



// riot.register('tamkin-sdk', TaminSDK)
riot.register('tamkin-player-sdk', prin)

// sample test page
riot.register('sample-page', SamplePage)
riot.mount('sample-page')


riot.mount('main-player')
riot.mount('tamkin-sdk')
riot.mount('tamkin-sdk-only-player')

riot.mount('Side-Menu')
riot.mount('tamkin-player-sdk')

