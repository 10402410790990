<main-component>

    <div>
        <!-- <h5>text</h5> -->

        <div class="player-icon-container">
            <div class="tp-hero-talk">
                <!--  <img src={loadFile(state.player_settings.icon_image)}>  -->
                <img src={loader} id="image_pricipal">
            </div>
            <div class="tp-hero-talk-text">
                <!-- accessible in asl -->
                { (state.player_settings?.icon_text) }
            </div>
        </div>

        <div class="tp-talk-card" id="player-card">

            <img src={loadFile('/assets/tamkin_website/img/close.png')} class="icon-close" id="icon-close-main">

            <div class="talk-content">

                <div id="loader" class="when-empty-card">
                    <lottie-player src={createBlob(state.handload)} background="transparent" speed="1" direction="1"
                        mode="normal" loop autoplay></lottie-player>
                    <div class="text-develop-container">
                        <a target="_blank" href={state.player_settings.developed_by_url} class="text-develop">{
                            (state.player_settings?.developed_by_text) } <i class="fa fa-edit"></i></a>
                    </div>
                </div>
                <div id="loader-hand">
                    <lottie-player src={createBlob(state.twohand)} background="transparent" speed="1" direction="1"
                        mode="normal" loop autoplay></lottie-player>
                </div>
                <div class="man-container">
                    <tamkin-sdk></tamkin-sdk>

                    <!-- <img src={loadFile('/assets/tamkin_website/img/MAN10.png')} class="img-man"> -->

                </div>
                <div class="btns-action" id="links-container">
                    <div id="loader-get-translation" class="">
                        <lottie-player src={createBlob(state.loading)} background="transparent" speed="1" direction="1"
                            mode="normal" loop autoplay></lottie-player>
                    </div>

                    <span class="menu-link just-a-placeholder"></span>

                    <span class="menu-link btn-play" id="playButt">
                        <img src={loadFile(state.player_settings.play_grey_icon)}>
                    </span>
                    <span class="menu-link btn-rate-translate">
                        <img id="icon-rate-before" src={loadFile(state.player_settings?.rate_grey_icon)}>
                        <img id="icon-rate-after" src={loadFile(state.player_settings.rate_icon)}>
                    </span>
                    <span class="menu-link btn-change-speed">
                        <span>1.0</span>
                    </span>
                    <span class="menu-link btn-tutorial">
                        <img src={loadFile(state.player_settings.learn_icon)} class="tamkin-logo">
                    </span>
                    <span class="menu-link btn-settings">
                        <img id="open-settings" src={loadFile(state.player_settings.settings_icon)}>
                    </span>
                </div>
                <div class="btns-config" id="config-btns-id" style="display: none;">
                    <span class="menu-link just-a-placeholder"></span>
                    <span class="menu-link">
                        <img src={loadFile(state.player_settings.contrast_day_icon)}>
                    </span>
                    <span class="menu-link">
                        <img src={loadFile(state.player_settings.view_icon)}>
                    </span>
                    <span class="menu-link">
                        <img src={loadFile(state.player_settings.position_icon)}>
                    </span>
                    <span class="menu-link">
                        <img src={loadFile(state.player_settings.keyboard_icon)}>
                    </span>
                </div>

                <div class="btns-info" id="info-btns-id" style="display: none;">
                    <span class="menu-link just-a-placeholder"></span>
                    <span class="menu-link">
                        <img src={loadFile(state.player_settings.tutorial_icon)}>
                    </span>
                    <span class="menu-link">
                        <img src={loadFile(state.player_settings.about_icon)}>
                    </span>
                </div>

                <div class="change-speed-card animated">
                    { ("Speed:") } <br> <span></span>
                </div>
                <div class="tutorial-card animated" id="tutorial-card-id" style="display: none;">
                    <div class="translate-item" id="tutorial">
                        <div class="icon"><img src={loadFile(state.player_settings.tutorial_icon)}></div>
                        { (state.player_settings?.tutorial_text) }
                    </div>
                    <div class="translate-item" id="about">
                        <div class="icon"><img src={loadFile(state.player_settings.about_icon)}></div>
                        { (state.player_settings?.about_text) }
                    </div>
                </div>
                <div class="settings-card animated" id="settings-card-id" style="display: none;">
                    <div class="settings-item" id="set-contrast">
                        <div class="icon"><img src={loadFile(state.player_settings.contrast_night_icon)}></div>
                        { (state.player_settings?.contrast_text) }
                    </div>
                    <div class="settings-item" id="set-background">
                        <div class="icon"><img src={loadFile(state.player_settings.view_icon)}></div>
                        { (state.player_settings?.view_text) }
                    </div>
                    <div class="settings-item" id="set-position">
                        <div class="icon"><img src={loadFile(state.player_settings?.position_icon)}></div>
                        { (state.player_settings?.position_text) }
                    </div>
                    <div class="settings-item" id="set-keyboard">
                        <input type="text" style="display: none;" id="tamkinInitialKb">
                        <div class="icon"><img src={loadFile(state.player_settings?.keyboard_icon)}></div>
                        { (state.player_settings?.keyboard_text) }
                    </div>

                </div>

                <div class="change-background-card animated"></div>

            </div>
            <div class="about-card animated" id="about-content" style="display: none;">
                <img src={loadFile('/assets/tamkin_website/img/close.png')} class="icon-close" id="icon-close-about">
                <div class="icon">
                    <img src={loadFile(state.player_settings.about_top_icon)} class="tamkin-logo">
                </div>
                <h3 class="text-main">{ (state.player_settings?.about_title) }</h3>
                <p>{ (state.player_settings?.about_description) }</p>
                <div class="btns-about-bottom asl-link">
                    <button class="btn btn-bg btn-asl">
                        <img src={loadFile(state.player_settings?.button_asl_icon)}>
                        { (state.player_settings?.button_asl_text) }
                    </button>
                    <a target="_blank" href={state.player_settings.developed_by_url} class="btn btn-border btn-tamkin">
                        <img src={loadFile(state.player_settings.button_tamkin_icon)}>
                        { (state.player_settings?.button_tamkin_text) }
                    </a>
                </div>
            </div>
            <div class="about-card animated" id="tutorial-content" style="display: none;">
                <img src={loadFile('/assets/tamkin_website/img/close.png')} class="icon-close" id="icon-close-tutorial">
                <div class="icon">
                    <img src={loadFile(state.player_settings?.tutorial_top_icon)} class="tamkin-logo">
                </div>
                <h3>{ (state.player_settings?.tutorial_title) }</h3>
                <p style="margin-bottom: 40px;">{ (state.player_settings?.tutorial_description) }</p>
                <div class="btns-about-bottom menu-trans">
                    <button class="btn btn-bg" id="btn-tutorial-menu">
                        <img src={loadFile(state.player_settings?.button_menu_icon)}> {
                        (state.player_settings?.button_menu_text) }
                    </button>
                    <button class="btn btn-bg" id="btn-tutorial-translation">
                        <img src={loadFile(state.player_settings?.button_translation_icon)}> {
                        (state.player_settings?.button_translation_text) }
                    </button>

                </div>
            </div>
            <div class="about-card animated" id="rate-translate-content" style="display: none;">
                <img src={loadFile('/assets/tamkin_website/img/close.png')} class="icon-close" id="icon-close-rate">
                <div class="icon">
                    <img src={loadFile(state.player_settings?.rating_top_icon)} class="tamkin-logo">
                </div>
                <h3>{ (state.player_settings?.rating_title) }</h3>
                <p>{ (state.player_settings?.rating_description) }</p>
                <div class="rate-translate">
                    <div class="check-rate">
                        <div class="item">
                            <input type="radio" class="btn-check" name="options" id="option1" autocomplete="off">
                            <label class="btn" for="option1">
                                <img class="rate-un" src={loadFile(state.player_settings?.poor_grey_icon)}>
                                <img class="rate-hover" src={loadFile(state.player_settings?.poor_icon)}>
                                <p>{ (state.player_settings?.poor_text) }</p>
                            </label>
                        </div>
                        <div class="item">
                            <input type="radio" class="btn-check" name="options" id="option2" autocomplete="off">
                            <label class="btn" for="option2">
                                <img class="rate-un" src={loadFile(state.player_settings?.good_grey_icon)}>
                                <img class="rate-hover" src={loadFile(state.player_settings?.good_icon)}>
                                <p>{ (state.player_settings?.good_text) }</p>
                            </label>
                        </div>

                    </div>
                    <button class="btn btn-bg" id="confirm-rate">{ (state.player_settings?.rating_button_text)
                        }</button>
                </div>

            </div>

            <div class="" id="finish-card">
                <div id="loader-finish-rate" class="">
                    <lottie-player src={createBlob(state.hearts)} background="transparent" speed="1" direction="1"
                        mode="normal" loop autoplay></lottie-player>
                </div>
                <div class="about-card" id="finish-rate">
                    <p>{ (state.player_settings?.rating_thanks_text) }</p>
                </div>
            </div>
            <side-menu message="{ state.player_settings }"></side-menu>
        </div>

        <accessibility-full-widget if="{state.accessibility_type == 'full'}"
            accessibility_settings="{ state.player_settings }">
        </accessibility-full-widget>
        <accessibility-mini-widget if="{state.accessibility_type == 'mini'}"
            accessibility_settings="{ state.player_settings }">
        </accessibility-mini-widget>

        <accessibility-minuscule-widget if="{state.accessibility_type == 'minuscule'}"
            accessibility_settings="{ state.player_settings }">
        </accessibility-minuscule-widget>
        <accessibility-round-widget if="{state.accessibility_type == 'round'}"
            accessibility_settings="{ state.player_settings }">
        </accessibility-round-widget>

        <!-- <language-widget if="{state.showLang== true}" accessibility_settings="{ state.player_settings }">
        </language-widget> -->


    </div>



    <script>
        // jQuery is now available in all project see webpack.config.js:126
        //import $ from 'jquery';
        //window.jQuery = window.$ = $;
        //jQuery.noConflict();

        import "@lottiefiles/lottie-player";
        import loader from "./player_files/images/h.svg"

        import SideMenu from './Side-Menu.riot'
        import handload from './player_files/json/handload.json';
        import hearts from './player_files/json/hearts.json';
        import loading from './player_files/json/loading.json';
        import twohand from './player_files/json/twohand.json';
        import AccessibilityFullWidget from '/src/accessibility/full_widget/accessibility.riot';
        import AccessibilityMiniWidget from '/src/accessibility/mini_widget/accessibility.riot';
        import AccessibilityMinusculeWidget from '/src/accessibility/minuscule_widget/accessibility.riot';
        import AccessibilityRoundWidget from '/src/accessibility/round_widget/accessibility.riot';
        import LanguageWidget from '/src/accessibility/language_widget/lang.riot';


        import TaminSDK from './tamkin-sdk.riot';
        const componentElement = document.querySelector('tamkin-player-sdk');
        let version;
        if (componentElement) {
            version = componentElement.getAttribute('with-player');
        }
        export default {
            components: {
                'tamkin-sdk': TaminSDK,
                'tamkin-sdk1': TaminSDK,
                'Side-Menu': SideMenu,
                'accessibility-full-widget': AccessibilityFullWidget,
                'accessibility-mini-widget': AccessibilityMiniWidget,
                'accessibility-minuscule-widget': AccessibilityMinusculeWidget,
                'accessibility-round-widget': AccessibilityRoundWidget,
                'language-widget': LanguageWidget,
            },
            state: {
                player_settings: {},
                with_player: version,
                handload: handload,
                hearts: hearts,
                loading: loading,
                twohand: twohand,
                accessibility_type: 'full',
                showLang: true,
            },
            loadFile(path) {
                return 'https://tamkin.app' + path;
            },

            createBlob(json) {

                const blob = new Blob([JSON.stringify(json)], { type: 'application/json' });

                // Create a URL object from the Blob
                const url = URL.createObjectURL(blob);
                return url;
            },

            onMounted() {
                let player_settings;
                const componentElement = document.querySelector('tamkin-player-sdk');
                let lang;
                if (componentElement) {
                    lang = componentElement.getAttribute('lang');
                }

                console.log(lang);
                window.tmk_trans_slow = ("Slow");
                window.tmk_trans_normal = ("Normal");
                window.tmk_trans_fast = ("Fast");
                window.tmk_trans_access_link = ("Access Link");


                // fetch('https://api.tamkin.app/v1/api/Widget/GetAccessibility')
                fetch('https://tamkin.app/api/method/tamkin_website.api.playerSettings?_lang=' + lang)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(data => {
                        this.state.player_settings = data.message;
                        // this.update();
                        this.update({ player_settings: data.message });
                        document.getElementById('image_pricipal').src = loadFile(data.message.icon_image);

                        //console.log(this.player_settings)
                    })
                    .catch(error => {
                        // console.error('Fetch error:', error);
                    });

                function loadFile(path) {
                    return 'https://tamkin.app' + path;
                }
            },
            onUpdated() {
            },
            alert(e) {
                alert(1)
            }
        }
    </script>
    <style>
        /* accessibility */
        :host #collapseLang .list-group-item.active::before {
            background-image: url('./images/check.svg');
        }
    </style>

</main-component>