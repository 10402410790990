<accessibility-sidebar>
    <div class="tp-accessibility4-sidebar no-style-scroll" id="tp-accessibility4-sidebar">
        <!-- body -->
        <div class="tp-accessibility4-sidebar-body tp-style-scroll">
            <div class="">
                <accessibility-controls if='{state.enabledFeatures.length}' enabledFeatures="{state.enabledFeatures}"
                    enabledFeaturesNames="{state.enabledFeaturesNames}">
                </accessibility-controls>
            </div>
        </div>


    </div>

    <script>
        import AccessibilityControls from '/src/accessibility/round_widget/accessibility-controls.riot';
        import AccessibilityProfiles from '/src/accessibility/full_widget/accessibility-profiles.riot';
        export default {
            components: {
                'accessibility-controls': AccessibilityControls,
                'accessibility-profiles': AccessibilityProfiles
            },
            state: {
                sidebarPosition: 'right',
                enabledFeatures: [],
                enabledFeaturesNames: [],
                defaultLang: { 'name': 'en', 'language_name': 'English (USA)', 'language_code': 'en', 'language_code_display': 'EN', 'image': '/files/us.svg' },
                languages: [
                    { "name": "af", "language_name": "Afrikaans" },
                    { "name": "sq", "language_name": "Albanian" },
                    { "name": "am", "language_name": "Amharic" },
                    { "name": "ar", "language_name": "Arabic" },
                    { "name": "hy", "language_name": "Armenian" },
                    { "name": "as", "language_name": "Assamese" },
                    { "name": "ay", "language_name": "Aymara" },
                    { "name": "az", "language_name": "Azerbaijani" },
                    { "name": "bm", "language_name": "Bambara" },
                    { "name": "eu", "language_name": "Basque" },
                    { "name": "be", "language_name": "Belarusian" },
                    { "name": "bn", "language_name": "Bengali" },
                    { "name": "bho", "language_name": "Bhojpuri" },
                    { "name": "bs", "language_name": "Bosnian" },
                    { "name": "bg", "language_name": "Bulgarian" },
                    { "name": "ca", "language_name": "Catalan" },
                    { "name": "ceb", "language_name": "Cebuano" },
                    { "name": "ny", "language_name": "Chichewa" },
                    { "name": "zh-CN", "language_name": "Chinese (Simplified)" },
                    { "name": "zh-TW", "language_name": "Chinese (Traditional)" },
                    { "name": "co", "language_name": "Corsican" },
                    { "name": "hr", "language_name": "Croatian" },
                    { "name": "cs", "language_name": "Czech" },
                    { "name": "da", "language_name": "Danish" },
                    { "name": "dv", "language_name": "Dhivehi" },
                    { "name": "doi", "language_name": "Dogri" },
                    { "name": "nl", "language_name": "Dutch" },
                    { "name": "eo", "language_name": "Esperanto" },
                    { "name": "et", "language_name": "Estonian" },
                    { "name": "ee", "language_name": "Ewe" },
                    { "name": "tl", "language_name": "Filipino" },
                    { "name": "fi", "language_name": "Finnish" },
                    { "name": "fr", "language_name": "French" },
                    { "name": "fy", "language_name": "Frisian" },
                    { "name": "gl", "language_name": "Galician" },
                    { "name": "ka", "language_name": "Georgian" },
                    { "name": "de", "language_name": "German" },
                    { "name": "el", "language_name": "Greek" },
                    { "name": "gn", "language_name": "Guarani" },
                    { "name": "gu", "language_name": "Gujarati" },
                    { "name": "ht", "language_name": "Haitian Creole" },
                    { "name": "ha", "language_name": "Hausa" },
                    { "name": "haw", "language_name": "Hawaiian" },
                    { "name": "iw", "language_name": "Hebrew" },
                    { "name": "hi", "language_name": "Hindi" },
                    { "name": "hmn", "language_name": "Hmong" },
                    { "name": "hu", "language_name": "Hungarian" },
                    { "name": "is", "language_name": "Icelandic" },
                    { "name": "ig", "language_name": "Igbo" },
                    { "name": "ilo", "language_name": "Ilocano" },
                    { "name": "id", "language_name": "Indonesian" },
                    { "name": "ga", "language_name": "Irish Gaelic" },
                    { "name": "it", "language_name": "Italian" },
                    { "name": "ja", "language_name": "Japanese" },
                    { "name": "jw", "language_name": "Javanese" },
                    { "name": "kn", "language_name": "Kannada" },
                    { "name": "kk", "language_name": "Kazakh" },
                    { "name": "km", "language_name": "Khmer" },
                    { "name": "rw", "language_name": "Kinyarwanda" },
                    { "name": "gom", "language_name": "Konkani" },
                    { "name": "ko", "language_name": "Korean" },
                    { "name": "kri", "language_name": "Krio" },
                    { "name": "ku", "language_name": "Kurdish (Kurmanji)" },
                    { "name": "ckb", "language_name": "Kurdish (Sorani)" },
                    { "name": "ky", "language_name": "Kyrgyz" },
                    { "name": "lo", "language_name": "Lao" },
                    { "name": "la", "language_name": "Latin" },
                    { "name": "lv", "language_name": "Latvian" },
                    { "name": "ln", "language_name": "Lingala" },
                    { "name": "lt", "language_name": "Lithuanian" },
                    { "name": "lg", "language_name": "Luganda" },
                    { "name": "lb", "language_name": "Luxembourgish" },
                    { "name": "mk", "language_name": "Macedonian" },
                    { "name": "mai", "language_name": "Maithili" },
                    { "name": "mg", "language_name": "Malagasy" },
                    { "name": "ms", "language_name": "Malay" },
                    { "name": "ml", "language_name": "Malayalam" },
                    { "name": "mt", "language_name": "Maltese" },
                    { "name": "mi", "language_name": "Maori" },
                    { "name": "mr", "language_name": "Marathi" },
                    { "name": "mni-Mtei", "language_name": "Meiteilon (Manipuri)" },
                    { "name": "lus", "language_name": "Mizo" },
                    { "name": "mn", "language_name": "Mongolian" },
                    { "name": "my", "language_name": "Myanmar (Burmese)" },
                    { "name": "ne", "language_name": "Nepali" },
                    { "name": "no", "language_name": "Norwegian" },
                    { "name": "or", "language_name": "Odia (Oriya)" },
                    { "name": "om", "language_name": "Oromo" },
                    { "name": "ps", "language_name": "Pashto" },
                    { "name": "fa", "language_name": "Persian" },
                    { "name": "pl", "language_name": "Polish" },
                    { "name": "pt", "language_name": "Portuguese" },
                    { "name": "pa", "language_name": "Punjabi" },
                    { "name": "qu", "language_name": "Quechua" },
                    { "name": "ro", "language_name": "Romanian" },
                    { "name": "ru", "language_name": "Russian" },
                    { "name": "sm", "language_name": "Samoan" },
                    { "name": "sa", "language_name": "Sanskrit" },
                    { "name": "gd", "language_name": "Scots Gaelic" },
                    { "name": "nso", "language_name": "Sepedi" },
                    { "name": "sr", "language_name": "Serbian" },
                    { "name": "st", "language_name": "Sesotho" },
                    { "name": "sn", "language_name": "Shona" },
                    { "name": "sd", "language_name": "Sindhi" },
                    { "name": "si", "language_name": "Sinhala" },
                    { "name": "sk", "language_name": "Slovak" },
                    { "name": "sl", "language_name": "Slovenian" },
                    { "name": "so", "language_name": "Somali" },
                    { "name": "es", "language_name": "Spanish" },
                    { "name": "su", "language_name": "Sundanese" },
                    { "name": "sw", "language_name": "Swahili" },
                    { "name": "sv", "language_name": "Swedish" },
                    { "name": "tg", "language_name": "Tajik" },
                    { "name": "ta", "language_name": "Tamil" },
                    { "name": "tt", "language_name": "Tatar" },
                    { "name": "te", "language_name": "Telugu" },
                    { "name": "th", "language_name": "Thai" },
                    { "name": "ti", "language_name": "Tigrinya" },
                    { "name": "ts", "language_name": "Tsonga" },
                    { "name": "tr", "language_name": "Turkish" },
                    { "name": "tk", "language_name": "Turkmen" },
                    { "name": "ak", "language_name": "Twi" },
                    { "name": "uk", "language_name": "Ukrainian" },
                    { "name": "ur", "language_name": "Urdu" },
                    { "name": "ug", "language_name": "Uyghur" },
                    { "name": "uz", "language_name": "Uzbek" },
                    { "name": "vi", "language_name": "Vietnamese" },
                    { "name": "cy", "language_name": "Welsh" },
                    { "name": "xh", "language_name": "Xhosa" },
                    { "name": "yi", "language_name": "Yiddish" },
                    { "name": "yo", "language_name": "Yoruba" },
                    { "name": "zu", "language_name": "Zulu" },
                ],
                filteredLanguages: [],
            },
            widgetPositionActive() {
                return this.isFeatureActive('widget-position')
            },
            accessibilityProfilesActive() {
                return this.isFeatureActive('accessibility-profiles')
            },
            oversizedWidgetActive() {
                return this.isFeatureActive('oversized-widget')
            },
            languageTranslationActive() {
                return this.isFeatureActive('language-translation')
            },
            isFeatureActive(featureName) {
                return this.state.enabledFeaturesNames.includes(featureName)
            },
            activeLang() {
                let activeLang = this.state.filteredLanguages.find(lang => lang.active);
                return activeLang ?? this.state.defaultLang
            },
            onBeforeMount(props, state) {
                // fetch('https://tamkin.app/api/resource/Google Language?fields=["name","language_name","language_code","image"]&filters=[["enabled","=","1"]]')
                fetch('https://tamkin.app/api/method/tamkin_website.api.getAccessibilityData')
                    .then(response => {
                        if (!response.ok)
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        return response.json();
                    })
                    .then(data => {
                        this.update({
                            languages: data.message.languages,
                            filteredLanguages: data.message.languages,
                            enabledFeatures: data.message.features,
                            enabledFeaturesNames: data.message.features.map(feature => feature.name),
                        });
                    })
                    .catch(error => { });

            },
            onMounted(props, state) {
                // fix: when opening the last accordion collapse in the sidebar it doesn't trigger scrolling to see its contents
                $('#tamkinPlayerApp #tp-accessibility4-sidebar .collapse-position-btn').on('click', function (e) {
                    let $sidebarBody = $('#tamkinPlayerApp #tp-accessibility4-sidebar .tp-accessibility4-sidebar-body');
                    // $sidebarBody.scrollTop($sidebarBody.prop("scrollHeight"));
                    $sidebarBody.animate({
                        scrollTop: $sidebarBody.prop("scrollHeight")
                    }, 500);
                });

                // set sidebar saved position
                this.setSidebarPositionDom(this.sidebarIsLeft() ? 'left' : 'right');

                // google trans
                var selectBoxContainer = document.querySelector("#google_translate_element");
                if (selectBoxContainer) {
                    var $this = this;
                    selectBoxContainer.addEventListener('elementLoaded', () => {
                        var selectBox = document.querySelector("#google_translate_element select.goog-te-combo");
                        let secondsPassed = 0;
                        let langInterval = setInterval(() => {
                            if (selectBox.value) {
                                $this.activateLanguage(selectBox.value);
                                clearInterval(langInterval);
                            }
                            if (secondsPassed > 5) {
                                clearInterval(langInterval);
                            }
                            secondsPassed++;
                        }, 1000);
                    })
                }
            },
            activateLanguage(active_lang) {
                // check lang_name is string or object
                let activeLanguage;
                if (typeof active_lang == 'string') {
                    activeLanguage = this.state.languages.find(l => l.name == active_lang);
                } else {
                    activeLanguage = active_lang
                }

                // set lang as active and deactivate others
                this.state.filteredLanguages.forEach(function (l) { if (l.active) { l.active = false } });
                activeLanguage.active = true;
                this.update()

                // clone the active language html to inside #g-lang-active (accordion btn)
                let activeLangHTML = $("#g-lang-" + activeLanguage.name + " .g-lang-inside").html()
                $("#g-lang-active .g-lang-inside").html(activeLangHTML);

                // close the language accordion
                if ($("#collapseLang").hasClass('show')) {
                    $("#collapseLang").removeClass('show')
                    $("#g-lang-active").attr('aria-expanded', 'false').removeClass('collapsed');
                }

                // rtl sidebar
                setTimeout(() => {
                    if ($('html').hasClass('translated-rtl')) {
                        $('#tamkinPlayerApp #tp-accessibility4-sidebar').addClass('rtl');
                    } else {
                        $('#tamkinPlayerApp #tp-accessibility4-sidebar').removeClass('rtl');
                    }
                }, 500);
            },
            onUpdated(props, state) {
                /**
                 * 
                 * ?note that if you are using props directly in the dom, the dom will update itself, unlike using the state
                 * so im commenting the code below because has no effect
                 * also in the dom I used support_email directly from the props
                 * also learn to pass props to child components if they are coming from a parent, don't assign them to state then pass them
                */
                /*
                if (!state.accessibility_settings && props.accessibility_settings?.accessibility_settings?.support_email) {
                    state.accessibility_settings = props.accessibility_settings?.accessibility_settings;
                    // this.update({accessibility_settings: props.accessibility_settings?.accessibility_settings});
                    console.log('no state and yes props',props.accessibility_settings?.accessibility_settings, state.accessibility_settings?.support_email);

                }
                */
            },
            searchLanguages(e) {
                let search = $(e.target).val();
                this.update({ filteredLanguages: this.state.languages.filter(lang => lang.language_name.toLowerCase().includes(search.toLowerCase())) });
            },
            removeSearchLanguageTrim(e) {
                $("#tp-search").val('')
                this.update({ filteredLanguages: this.state.languages })
            },
            changeLanguage(e, lang) {
                var selectBox = document.querySelector("#google_translate_element select.goog-te-combo");
                if (selectBox && selectBox.value != lang.name) {
                    // trigger google change translate the website
                    selectBox.value = lang.name;
                    var event = new Event('change', { 'bubbles': true, 'cancelable': true });
                    selectBox.dispatchEvent(event);

                    $('body *:not(div.notranslate *)').each(function () {
                        if ($(this).children().length === 0 && $(this).text().trim() !== '') {
                            $(this).addClass('highlight');
                        }
                    });

                    // sometimes this doesn't work, so i try to trigger it again depending on html tag has eigher 'translated-ltr' or 'translated-rtl'
                    setTimeout(() => {
                        $('.highlight').removeClass('highlight');
                        if (!$('html').hasClass('translated-rtl') && !$('html').hasClass('translated-ltr')) {
                            $(e.target).trigger('click');
                        }

                        this.activateLanguage(lang);
                    }, 1500);

                    // reset language
                    // setTimeout(() => {
                    //     let reset_button = document.querySelector('[id*=":1.restore"]');
                    //     console.log(reset_button);
                    //     reset_button.click();
                    // }, 4000);

                }
            },
            loadFile(path) {
                return 'https://tamkin.app' + path;
            },
            oversized(e) {
                if ($('.nav-link[href="#yes"]').hasClass('active')) {
                    $('#tamkinPlayerApp #tp-accessibility4-sidebar').addClass('oversized');
                } else if ($('.nav-link[href="#no"]').hasClass('active')) {
                    $('#tamkinPlayerApp #tp-accessibility4-sidebar').removeClass('oversized');
                }
            },
            sidebarPosition(e) {
                if ($(e.target).attr('value') == 'left') {
                    this.update({ sidebarPosition: 'left' })
                    localStorage.setItem('tpSidebarPosition', 'left');
                    this.setSidebarPositionDom('left');
                } else if ($(e.target).attr('value') == 'right') {
                    this.update({ sidebarPosition: 'right' })
                    localStorage.setItem('tpSidebarPosition', 'right');
                    this.setSidebarPositionDom('right');
                } else if ($(e.target).attr('value') == 'hide') {
                    this.update({ sidebarPosition: 'hide' })
                }
            },
            setSidebarPositionDom(position = 'right') {
                if (position === 'left') {
                    $('#tamkinPlayerApp #tp-accessibility4-sidebar').addClass('ltr');
                    $('#tamkinPlayerApp .accessibility-icon-container').addClass('ltr');
                } else {
                    $('#tamkinPlayerApp #tp-accessibility4-sidebar').removeClass('ltr');
                    $('#tamkinPlayerApp .accessibility-icon-container').removeClass('ltr');
                }
            },
            sidebarHide(e) {
                let duration = $(e.target).val();
                if (duration === 'always') {
                    localStorage.setItem('tpAccHideUntil', 'forever');
                } else {
                    const now = new Date();
                    let tpAccHideUntil = new Date();
                    switch (duration) {
                        case 'session':
                            tpAccHideUntil.setTime(now.getTime() + 20 * 1000); // 3600000 ms = 1 hour
                            break;
                        case 'day':
                            tpAccHideUntil.setDate(now.getDate() + 1);
                            break;
                        case 'week':
                            tpAccHideUntil.setDate(now.getDate() + 7);
                            break;
                        case 'month':
                            tpAccHideUntil.setMonth(now.getMonth() + 1);
                            break;
                    }
                    localStorage.setItem('tpAccHideUntil', tpAccHideUntil.toString());
                }

                this.update();

                $('accessibility > div').fadeOut().delay(2000).remove();
            },
            sidebarIsHidden() {
                const tpAccHideUntil = localStorage.getItem('tpAccHideUntil');
                if (!tpAccHideUntil) {
                    return false;
                } else {
                    if (new Date() < new Date(tpAccHideUntil)) {
                        return true;
                    } else {
                        localStorage.removeItem('tpAccHideUntil');
                        return false;
                    }
                }
            },
            sidebarIsLeft() {
                return this.state.sidebarPosition === 'left' || localStorage.getItem('tpSidebarPosition') === 'left';
            },
            HidePostion(e) {
                $("#tp-accordion-hide").toggle()
            }

        }
    </script>

    <style>
    </style>
</accessibility-sidebar>