<voice-navigation>
    <div id="voice-nav-modal">
        <div class="voice-nav-header d-flex align-items-center justify-content-between">
            <div class=" d-flex justify-content-between" style="gap: 15px;">
                <button id="openlisten">
                    <img src="./images/mic.svg" alt="Close">
                </button>
                <p class="titleModal mt-2">Please allow access permission to your microphone</p>
            </div>

            <div class=" d-flex justify-content-between" style="gap: 15px;">
                <img src="./images/addvoice.svg" onclick="{updateShowDetails}" if="{state.showDetails==false}"
                    alt="add">
                <img src="./images/minvoice.svg" onclick="{updateHideDetails}" if="{state.showDetails==true}" alt="min">
                <img src="./images/closebtn.svg" onclick="{closeVoiceNavigation}" alt="Close">
            </div>
        </div>
        <hr if="{state.showDetails==true}" style="border: 1px solid #A6A6A6;" />
        <div class="voice-nav-body" if="{state.showDetails==true}">
            <div each="{command in state.commands}" class="command-item" key="{command.titlebold}">
                <div>
                    <span class="title-bold">{command.titlebold}</span>
                    <span class="title-thin">
                        {command.titlethin}
                    </span>
                </div>
                <div style="display: flex;gap: 10px;flex-wrap: wrap;margin: 10px 0px;">
                    <div class="command-item" each="{item in command.commands}">{item}</div>
                </div>

            </div>
        </div>
    </div>


    <script>
        const importAllImages = (requireContext) => requireContext.keys().map(requireContext);
        const images = importAllImages(require.context('/src/player_files/accessibility/img', false, /\.(png|jpe?g|svg)$/));

        export default {
            state: {
                showDetails: false,
                commands: [
                    {
                        titlebold: '"Help (Show available commands)"',
                        titlethin: ' command and aliases:',
                        commands: [
                            "Help me", "Please help", "Show Commands", "Available Commands", "List Commands"
                        ]
                    },
                    {
                        titlebold: '"Hide help"',
                        titlethin: ' command and aliases:',
                        commands: [
                            "Help me", "Hide Commands"
                        ]
                    },
                    {
                        titlebold: '"Show numbers"',
                        titlethin: ' command and aliases:',
                        commands: [
                            "Show Numbers", "Hide Numbers"
                        ]
                    },
                    {
                        titlebold: '"Scroll down"',
                        titlethin: ' command and aliases:',
                        commands: [
                            "Scroll Down", "Down", "Page Down", "Go Down"
                        ]
                    },
                    {
                        titlebold: '"Scroll up"',
                        titlethin: ' command and aliases:',
                        commands: [
                            "Scroll Up", "Up", "Page Up", "Go Up"
                        ]
                    },
                    {
                        titlebold: '"Go to top"',
                        titlethin: ' command and aliases:',
                        commands: [
                            "Go to Top", "Scroll to Top"
                        ]
                    },
                    {
                        titlebold: '"Go to bottom"',
                        titlethin: ' command and aliases:',
                        commands: [
                            "Go to Bottom", "Scroll to Bottom"
                        ]
                    },
                    {
                        titlebold: '"Tap"',
                        titlethin: ' command and aliases:',
                        commands: [
                            "Tap", "Next"
                        ]
                    },
                    {
                        titlebold: '"Tap back"',
                        titlethin: ' command and aliases:',
                        commands: [
                            "Tap back", "Back", "Previous"
                        ]
                    },
                    {
                        titlebold: '"Click number"',
                        titlethin: ' command and aliases:',
                        commands: [
                            "Click [Number]", "Go [Number]", "Open [Number]", "Press [Number]"
                        ]
                    },
                    {
                        titlebold: '"Select number"',
                        titlethin: ' command and aliases:',
                        commands: [
                            "Select [Number]", "Focus [Number]", "Show [Number]"
                        ]
                    },
                    {
                        titlebold: '"Numbers"',
                        titlethin: ' command and aliases:',
                        commands: [
                            "[Number]"
                        ]
                    },
                    {
                        titlebold: '"Hide numbers"',
                        titlethin: ' command and aliases:',
                        commands: [
                            "Hide numbers"
                        ]
                    },
                    {
                        titlebold: '"Move Up"',
                        titlethin: ' command and aliases:',
                        commands: [
                            "Move Up", "Up"
                        ]
                    },
                    {
                        titlebold: '"Move Left"',
                        titlethin: ' command and aliases:',
                        commands: [
                            "Move Left", "Left"
                        ]
                    },
                    {
                        titlebold: '"Move Right"',
                        titlethin: ' command and aliases:',
                        commands: [
                            "Move Right", "Right"
                        ]
                    },
                    {
                        titlebold: '"Move Down"',
                        titlethin: ' command and aliases:',
                        commands: [
                            "Move Down", "Down"
                        ]
                    },
                    {
                        titlebold: '"Clear input"',
                        titlethin: ' command and aliases:',
                        commands: [
                            "Clear input", "Erase", "Remove", "Delete"
                        ]
                    },
                    {
                        titlebold: '"Enter"',
                        titlethin: ' command and aliases:',
                        commands: [
                            "Enter"
                        ]
                    },
                    {
                        titlebold: '"Stop Voice Navigation"',
                        titlethin: ' command and aliases:',
                        commands: [
                            "Stop Voice Navigation", "Stop"
                        ]
                    },
                    {
                        titlebold: '"Reload the page"',
                        titlethin: ' command and aliases:',
                        commands: [
                            "Reload page", "Refresh page"
                        ]
                    },
                    {
                        titlebold: '"Exit"',
                        titlethin: ' command and aliases:',
                        commands: [
                            "Exit"
                        ]
                    },
                    {
                        titlebold: '"Close widget"',
                        titlethin: ' command and aliases:',
                        commands: [
                            "Close widget", "Hide widget"
                        ]
                    },
                ]
            },
            onMounted(state, props) {
                console.log("props", props.currentLang);

                this.startListen();
            },

            startListen() {
                this.state.showDetails = true
                var recordAudioButton = document.getElementById("openlisten");
                var mediaRecorder;
                var audioContext;
                var scriptProcessor;
                var autoStopAfter = 4000;
                var time;
                var isRecording = false;

                var run = function (stream) {
                    audioContext = new (window.AudioContext || window.webkitAudioContext)();
                    scriptProcessor = audioContext.createScriptProcessor(4096, 1, 1);
                    var source = audioContext.createMediaStreamSource(stream);
                    source.connect(scriptProcessor);
                    scriptProcessor.connect(audioContext.destination);

                    scriptProcessor.onaudioprocess = function (event) {
                        var inputData = event.inputBuffer.getChannelData(0);
                        var hasAudio = inputData.some(value => Math.abs(value) > 0.01); // Adjust threshold as needed

                        // if (hasAudio && !isRecording) {
                        //     startRec();
                        //     time = setTimeout(stopRec, autoStopAfter);
                        // }
                    };

                    mediaRecorder = new MediaRecorder(stream);
                    mediaRecorder.ondataavailable = getAudio;
                    recordAudioButton.addEventListener("click", recordHandler);
                };

                function recordHandler() {
                    if (isRecording) {
                        stopRec();
                    } else {
                        startRec();
                        time = setTimeout(stopRec, autoStopAfter);
                    }
                }

                function startRec() {
                    const speech = new SpeechSynthesisUtterance('listening');
                    speech.lang = `en-US`;
                    speech.rate = 1.0;

                    window.speechSynthesis.speak(speech);

                    speech.onend = () => {
                        window.speechSynthesis.cancel();
                    };

                    $("#openlisten").addClass("active");
                    mediaRecorder.start();
                    isRecording = true;
                }

                function stopRec() {
                    mediaRecorder.stop();
                    isRecording = false;
                    $("#openlisten").removeClass("active");
                }

                function getAudio(event) {
                    if (event.data.size > 0) {
                        var reader = new FileReader();
                        reader.onloadend = function () {
                            var base64data = reader.result;
                            sendToServer(base64data);
                        };
                        reader.readAsDataURL(event.data);
                    }
                }

                function sendToServer(base64String) {
                    fetch('https://api.tamkin.app/v1/api/Widget/Voice2Text', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ audio: base64String })
                    })
                        .then(response => response.json())
                        .then(data => console.log(data))
                        .catch(error => console.error('Error sending audio:', error));
                }

                const enableAudio = { audio: true };
                navigator.mediaDevices.getUserMedia(enableAudio).then(run).catch(error => console.error('Error accessing media devices.', error));
            },

            closeVoiceNavigation() {
                $("#voice-nav-modal").removeClass("active")
            },
            updateShowDetails(payload) {
                this.state.showDetails = true;
                this.update();
            },
            updateHideDetails(payload) {
                this.state.showDetails = false;
                this.update();
            },



        }
    </script>
    <style>
        #voice-nav-modal {
            position: fixed;
            bottom: -100%;
            transition: all 0.5s;
            left: 50%;
            transform: translateX(-50%);
            z-index: 3000;
            padding: 20px;
            background-color: #F2F2F2;
            box-shadow: 0px 4px 8px 0px #00000014;
            border-radius: 8px;
            max-width: 792px;
            width: 80%;
        }

        #voice-nav-modal.active {
            bottom: 100px;
        }

        #voice-nav-modal img {
            cursor: pointer;
        }

        .voice-nav-header .titleModal {
            font-size: 16px;
            font-weight: 500;
            color: #1E1E1E;
        }

        .voice-nav-body {
            transition: all 0.5s;
            max-height: 300px;
            overflow-y: auto;
            scrollbar-width: thin;
        }

        .command-item .title-bold {
            font-weight: 600;
            font-size: 16px;
            color: #000;
        }

        .command-item .title-thin {
            font-weight: 600;
            font-size: 16px;
            color: #A6A6A6;
        }

        .command-item .command-item {
            padding: 8px 12px;
            border: 2px solid var(--tp-primary);
            font-weight: 600;
            font-size: 14px;
            color: var(--tp-primary);
            border-radius: 8px;
        }

        @keyframes shadowWaveAnimation {
            0% {
                box-shadow: 0 0 0 rgba(53, 192, 180, 0);
            }

            50% {
                box-shadow: 0 0 30px 15px rgba(53, 192, 180, 0.8);
            }

            100% {
                box-shadow: 0 0 0 rgba(53, 192, 180, 0);
            }
        }

        #openlisten {
            border-radius: 100%;
            position: relative;
        }

        #openlisten.active {
            animation: shadowWaveAnimation 2s ease infinite;
            animation-direction: normal;
            animation-fill-mode: none;
            animation-play-state: running;
        }

        @keyframes pulseAnimation {
            0% {
                transform: scale(1);
                opacity: 1;
            }

            50% {
                transform: scale(1.5);
                opacity: 0.5;
            }

            100% {
                transform: scale(2);
                opacity: 0;
            }
        }

        #openlisten.active:before {
            content: "";
            animation: pulseAnimation 1.05s ease-out 1s infinite;
            border-radius: 50%;
            /* Ensures the element remains circular */
            position: absolute;
            z-index: -1;
            border: 1px solid var(--tp-primary);
            height: 50px;
            width: 50px;
            left: 0%;
            top: 0%;
            /* transform: translate(-50%, -50%); */
            /* Centers the pseudo-element */
        }
    </style>
</voice-navigation>