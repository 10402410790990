<language-widget>

    <div>

        <div>
            <div class="accessibility-icon-container">
                <div class="tp-lang-modal-icon" onclick="{showSideLang}">
                    <img src="./images/lang.svg">
                </div>
            </div>
        </div>

        <div class="tp-accessibility-lang" id="tp-accessibility-lang">
            <div class="tp-accessibility-lang-header text-white border-bottom
                    d-flex align-items-center justify-content-between">
                <p class="font-16 font-w500 mb-0">
                    Select language
                </p>
                <a onclick="{showSideLang}" class="tp-accessibility-lang-close rounded-circle"
                    href="javascript:void(0)">
                    <div></div>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18" stroke="white" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </a>

            </div>

            <!-- body -->
            <div class="tp-lang-sidebar-body">
                { props.accessibility_settings.languages}
                <div class="mx-3 mt-3" id="languages-box">
                    <div class="form-group mb-3 px-1" id="tp-search-gp">
                        <label for="tp-search"><img class="h-25px" src="./images/icon-search.svg" alt="search"></label>
                        <input oninput="{searchLanguages}" type="text" id="tp-search"
                            class="form-control rounded-4 ps-5 h-50px" placeholder="Search by Keyword...">
                        <span if="{ document.getElementById('tp-search').value.length> 0}"
                            onclick="{(e) => removeSearchLanguageTrim(e)}" id="tp-search-remove">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 6L6 18" stroke="#585B5B" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path d="M6 6L18 18" stroke="#585B5B" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </span>
                    </div>
                    <hr class="mb-2">
                    <div class="list-group list-group-flush notranslate">
                        <a each="{(lang, index) in state.filteredLanguages}" id="g-lang-{lang.name}" key="{lang.name}"
                            href="javascript:void(0)" onclick="{(e) => changeLanguage(e, lang)}" class="{(activeLang())?.name && lang.name==(activeLang()).name?'active':''} px-4 list-group-item 
                            list-group-item-action d-flex justify-content-between align-items-center">
                            <div class="d-flex g-lang-inside">
                                <div class="rounded-circle tp-icon text-center">
                                    <span>{lang.language_code_display}</span>
                                </div>
                                <span
                                    class="px-2 font-16 font-w400 mb-0 align-content-center">{lang.language_name}</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <script>
        import "/src/player_files/accessibility/bootstrap.min.js";
        export default {
            components: {
            },
            state: {
                defaultLang: { 'name': 'en', 'language_name': 'English (USA)', 'language_code': 'en', 'language_code_display': 'EN', 'image': '/files/us.svg' },
                languages: [],
                filteredLanguages: [],
            },

            showSideLang() {
                $("#tp-accessibility-lang").toggleClass("active")
            },


            activeLang() {
                let activeLang = this.state.filteredLanguages.find(lang => lang.active);
                return activeLang ?? this.state.defaultLang
            },
            onBeforeMount(props, state) {
                fetch('https://tamkin.app/api/method/tamkin_website.api.getAccessibilityData')
                    .then(response => {
                        if (!response.ok)
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        return response.json();
                    })
                    .then(data => {
                        this.update({
                            languages: data.message.languages,
                            filteredLanguages: data.message.languages,
                        });
                    })
                    .catch(error => { });

            },
            onMounted(props, state) {

                console.log("accessibility_settings", this.props);

                // google trans
                var selectBoxContainer = document.querySelector("#google_translate_element");
                if (selectBoxContainer) {
                    var $this = this;
                    selectBoxContainer.addEventListener('elementLoaded', () => {
                        var selectBox = document.querySelector("#google_translate_element select.goog-te-combo");
                        let secondsPassed = 0;
                        let langInterval = setInterval(() => {
                            if (selectBox.value) {
                                $this.activateLanguage(selectBox.value);
                                clearInterval(langInterval);
                            }
                            if (secondsPassed > 5) {
                                clearInterval(langInterval);
                            }
                            secondsPassed++;
                        }, 1000);
                    })
                }
            },
            activateLanguage(active_lang) {
                // check lang_name is string or object
                let activeLanguage;
                if (typeof active_lang == 'string') {
                    activeLanguage = this.state.languages.find(l => l.name == active_lang);
                } else {
                    activeLanguage = active_lang
                }

                // set lang as active and deactivate others
                this.state.filteredLanguages.forEach(function (l) { if (l.active) { l.active = false } });
                activeLanguage.active = true;
                this.update()

                // clone the active language html to inside #g-lang-active (accordion btn)
                let activeLangHTML = $("#g-lang-" + activeLanguage.name + " .g-lang-inside").html()
                $("#g-lang-active .g-lang-inside").html(activeLangHTML);

                // close the language accordion
                if ($("#languages-box").hasClass('show')) {
                    $("#languages-box").removeClass('show')
                    $("#g-lang-active").attr('aria-expanded', 'false').removeClass('collapsed');
                }

                // rtl sidebar
                setTimeout(() => {
                    if ($('html').hasClass('translated-rtl')) {
                        $(' #tp-accessibility-sidebar').addClass('rtl');
                    } else {
                        $(' #tp-accessibility-sidebar').removeClass('rtl');
                    }
                }, 500);
            },
            onUpdated(props, state) {

            },
            searchLanguages(e) {
                let search = $(e.target).val();
                this.update({ filteredLanguages: this.state.languages.filter(lang => lang.language_name.toLowerCase().includes(search.toLowerCase())) });
            },
            removeSearchLanguageTrim(e) {
                $("#tp-search").val('')
                this.update({ filteredLanguages: this.state.languages })
            },
            changeLanguage(e, lang) {
                var selectBox = document.querySelector("#google_translate_element select.goog-te-combo");
                if (selectBox && selectBox.value != lang.name) {
                    // trigger google change translate the website
                    selectBox.value = lang.name;
                    var event = new Event('change', { 'bubbles': true, 'cancelable': true });
                    selectBox.dispatchEvent(event);

                    $('body *:not(div.notranslate *)').each(function () {
                        if ($(this).children().length === 0 && $(this).text().trim() !== '') {
                            $(this).addClass('highlight');
                        }
                    });

                    // sometimes this doesn't work, so i try to trigger it again depending on html tag has eigher 'translated-ltr' or 'translated-rtl'
                    setTimeout(() => {
                        $('.highlight').removeClass('highlight');
                        if (!$('html').hasClass('translated-rtl') && !$('html').hasClass('translated-ltr')) {
                            $(e.target).trigger('click');
                        }

                        this.activateLanguage(lang);
                    }, 1500);

                }
            },




        }
    </script>

    <style>
        .tp-lang-modal-icon {
            position: fixed;
            top: 40%;
            right: 0;
            cursor: pointer;
        }

        .tp-accessibility-lang {
            position: fixed;
            top: 30%;
            right: -100%;
            width: 350px;
            z-index: 1042;
            height: 400px;
            background-color: #FFF;
            transition: 0.7s;
            border-radius: 8px;
        }

        .tp-accessibility-lang.active {
            right: 80px;
        }

        .tp-accessibility-lang.ltr.active {
            left: 20px;
            right: auto;
        }

        .tp-accessibility-lang-header {
            height: 56px;
            background: linear-gradient(180deg, #2DADA3 0%, #71DAD2 100%);
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            padding: 0px 15px;
        }

        .tp-accessibility-lang.ltr .tp-accessibility-lang-header {
            margin-left: 10px;
            margin-right: auto;
        }


        .tp-accessibility-lang-close {
            background-color: white;
            width: 35px;
            height: 35px;
            transition: all 0.5s;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .tp-accessibility-lang-close div {
            background-color: #585b5b;
            text-decoration: none;
            width: 20px;
            height: 3px;
            border-radius: 2px;
        }

        .tp-accessibility-lang-close:hover {
            background: linear-gradient(180deg, #2dada3 0%, #71dad2 100%);
            box-shadow: 1px 0px 10.6px 0px #71dad2;
        }

        .tp-accessibility-lang-close svg {
            display: none;
        }

        .tp-accessibility-lang-close:hover svg {
            display: block;
        }

        .tp-accessibility-lang-close:hover div {
            display: none;
        }


        /* accordion */
        .list-group-flush>.list-group-item:last-child {
            border-width: 0 0 var(--bs-list-group-border-width);
        }

        .tp-icon {
            width: 36px !important;
            height: 36px !important;
            align-content: center;
            text-align: center;
        }

        .tp-icon.lg {
            width: 50px !important;
            height: 50px !important;
        }

        .tp-icon.sm {
            width: 28px !important;
            height: 28px !important;
        }

        #languages-box .form-group label {
            position: absolute;
            top: 13px;
            left: 1rem;
        }

        #languages-box .list-group-item .tp-icon {
            background-color: var(--tp-primary-light);
            color: var(--tp-primary);
        }

        #languages-box .list-group-item.active .tp-icon,
        #g-lang-active .tp-icon {
            background-color: var(--tp-primary);
            color: white;
        }

        #languages-box .list-group-item.active {
            background-color: var(--tp-primary-light);
            border-color: var(--tp-primary-light);
            color: var(--tp-dark);
        }

        .collapsing {
            transition: all 0.1s !important;
        }

        #languages-box .list-group {
            max-height: 220px;
            overflow-y: auto;
            scrollbar-width: thin;
            scrollbar-color: var(--tp-primary) #f1f1f1;
        }

        #languages-box .list-group-item.active::before {
            content: "";
            position: absolute;
            right: 24px;
            top: 14px;
            width: 24px;
            height: 24px;
            background-size: contain;
            background-repeat: no-repeat;
        }

        .rtl #languages-box .list-group-item.active::before {
            right: auto;
            left: 24px;
        }
    </style>
</language-widget>