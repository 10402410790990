$(function () {
  window.accessibility_actions_opened = false;
  $("#tamkinPlayerApp .tp-accessibility4").hover(
    function () {
      if (!window.accessibility_actions_opened) {
        $("#tamkinPlayerApp .tp-accessibility4-text").addClass("active");
      }
    },
    function () {
      $("#tamkinPlayerApp .tp-accessibility4-text").removeClass("active");
    }
  );

  $("#tamkinPlayerApp #linkInput, #tamkinPlayerApp #liveInput").on(
    "input",
    function () {
      if ($(this).val() != "") {
        $(this).next("button").attr("disabled", false);
      } else {
        $(this).next("button").attr("disabled", true);
      }
    }
  );

  window.toggleAccessibilityActions = function () {
    if (window.accessibility_actions_opened) {
      $("#tamkinPlayerApp .tp-accessibility4-actions").removeClass("active");
      $("#tamkinPlayerApp .tp-accessibility4 img").attr(
        "src",
        "./images/accessibility.svg"
      );
      window.accessibility_actions_opened = false;
    } else {
      $("#tamkinPlayerApp .tp-accessibility4-actions").addClass("active");
      $("#tamkinPlayerApp .tp-accessibility4-text").removeClass("active");
      $("#tamkinPlayerApp .tp-accessibility4 img").attr(
        "src",
        "./images/access-close.svg"
      );
      window.accessibility_actions_opened = true;
    }
  };

  window.toggleAccessibility = function () {
    $("#tamkinPlayerApp #circle-container").toggleClass("active");
    // $("#tamkinPlayerApp .tp-accessibility4").toggle();
  };
  window.openAccessibility = function () {
    $("#tamkinPlayerApp .tp-accessibility4-sidebar").addClass("active");
    $("#tamkinPlayerApp #circle-container").addClass("active");
    $("#tamkinPlayerApp .tp-accessibility4").toggle();
    // $('body').toggleClass('stop');
    // $('#tamkinPlayerApp #sidebody').toggleClass('active');
  };
  window.closeAccessibility = function () {
    $("#tamkinPlayerApp .tp-accessibility4-sidebar").removeClass("active");
    $("#tamkinPlayerApp #circle-container").removeClass("active");
    $("#tamkinPlayerApp .tp-accessibility4").toggle();
    // $('body').removeClass('stop');
    // $('#tamkinPlayerApp #sidebody').removeClass('active');
  };

  // $('#tamkinPlayerApp .tp-accessibility4').on('click', window.toggleAccessibilityActions);
  $("#tamkinPlayerApp .tp-accessibility4").on(
    "click",
    window.openAccessibility
  );

  $(".tp-accessibility4-sidebar-open").on("click", window.openAccessibility);
  $(".tp-accessibility4-sidebar-close").on("click", window.closeAccessibility);
  // close accessibility sidebar when clicking outside it
  $(
    "body > *:not(#tamkinPlayerApp,tamkin-player-sdk, #tamkinPlayerApp *,tamkin-player-sdk *)"
  ).on("click", window.closeAccessibility);

  // dropzone
  var $dropZone = $("#tamkinPlayerApp #mediaUpload");
  var $fileInput = $("#tamkinPlayerApp #mediaUploadInput");
  var $uploadError = $("#tamkinPlayerApp #upload-error");
  var $progressContainer = $("#tamkinPlayerApp #upload-progress-container");
  var $progressBar = $("#tamkinPlayerApp #upload-progress");
  var maxFileSizeMB = 200;
  $("#tamkinPlayerApp #maxSizeMB").text(maxFileSizeMB);

  var $btnUpload = $("#tamkinPlayerApp #upload .btn-translate-upload");

  window.validateFile = function (file) {
    var maxFileSize = maxFileSizeMB * 1024 * 1024; // 200MB in bytes
    var isValidType = /\.(mp3|mp4)$/i.test(file.name);
    // var isValidType = file.type.startsWith('audio/') || file.type.startsWith('video/');
    var isValidSize = file.size <= maxFileSize;
    // Inform the user if some files were rejected
    if (!isValidType) {
      $uploadError.text("Only audio and video files are accepted.").show();
      return false;
    }
    if (!isValidSize) {
      $uploadError.text(`Maximum file size is ${maxFileSizeMB}MB.`).show();
      return false;
    }
    return isValidType && isValidSize;
  };
  window.uploadStarted = function () {
    $btnUpload.attr("disabled", "disabled");
    $("#uploadContainer").hide();
    $("#filePreview").show();
  };
  window.uploadFinished = function () {
    $btnUpload.removeAttr("disabled");
    // set btn data audio or video
    $("#tamkinPlayerApp #filePreview").removeClass("py-3").addClass("py-1");
    $("#tamkinPlayerApp #filePreview .btn-close").css("top", "2rem");
    $("#tamkinPlayerApp .img-upload-success").show();
    $progressContainer.hide();
    $progressBar.width("0%");
    $uploadError.text("").hide();
  };
  window.resetUpload = function () {
    $fileInput.val("");
    $("#tamkinPlayerApp #filePreview").removeClass("py-1").addClass("py-3");
    $("#tamkinPlayerApp #filePreview .btn-close").css("top", "1rem");

    $("#tamkinPlayerApp #uploadContainer").show();
    $("#tamkinPlayerApp .img-upload-success").hide();
    $("#tamkinPlayerApp #filePreview").hide();
  };
  window.fileUpload = function (file) {
    var fileSize = file.size;
    $("#tamkinPlayerApp #filePreview #fileName").text(file.name);
    $("#tamkinPlayerApp #filePreview #fileSize").text(
      (fileSize / (1024 * 1024)).toFixed(2) + " MB"
    );

    var formData = new FormData();
    formData.append("file", file);
    window.uploadEnded = false;
    $.ajax({
      url: "http://5.189.177.174:5011/api/v1/user/caption",
      type: "POST",
      data: formData,
      processData: false, // tell jQuery not to process the data
      contentType: false, // tell jQuery not to set contentType
      xhr: function () {
        const myXhr = $.ajaxSettings.xhr();
        if (myXhr.upload) {
          // For handling the progress of the upload
          myXhr.upload.addEventListener(
            "progress",
            function (e) {
              if (e.lengthComputable) {
                let total = e.total; // * 5;
                let percentage = (e.loaded / total) * 100;
                $progressContainer.show();
                $progressContainer
                  .find("span")
                  .text(`${Math.round(percentage)}%`);
                $progressBar.width(Math.min(100, percentage) + "%");
                if (e.loaded == e.total) {
                  $progressContainer.find("span").text("Processing...");
                }
              }
            },
            false
          );
        }
        return myXhr;
      },
      success: function (data) {
        window.uploadEnded = true;
        let generatedTextEl =
          window.currentFiletype == "audio"
            ? $("#tamkinPlayerApp .generated-text-audio")
            : $("#tamkinPlayerApp .generated-text-video");
        generatedTextEl.text(data);
        window.uploadFinished();
      },
      error: function (xhr, status, error) {
        $uploadError.text(`Upload failed: ${error}`).show();
      },
      complete: function () {},
    });
  };
  window.processFiles = function (files) {
    var validFiles = Array.from(files).filter((file) =>
      window.validateFile(file)
    );
    validFiles.forEach(function (file, index) {
      console.log("Uploading file:", file.name);
      window.uploadStarted();

      const fileType = file.type;
      if (fileType.startsWith("audio/")) {
        window.currentFiletype = "audio";
      } else if (fileType.startsWith("video/")) {
        window.currentFiletype = "video";
      } else {
        console.log("Unsupported file type:", fileType);
        return;
      }
      window.fileUpload(file);
    });

    // if (validFiles.length) {
    //     resetUpload();
    // }
  };

  $("#tamkinPlayerApp #filePreview button.btn-close").on(
    "click",
    window.resetUpload
  );

  $dropZone.on("dragover", function (e) {
    e.preventDefault();
    e.stopPropagation();
    $(this).addClass("dragover");
  });

  $dropZone.on("dragleave", function (e) {
    e.preventDefault();
    e.stopPropagation();
    $(this).removeClass("dragover");
  });

  $dropZone.on("drop", function (e) {
    e.preventDefault();
    e.stopPropagation();
    $(this).removeClass("dragover");

    // Get the files that were dropped
    var files = e.originalEvent.dataTransfer.files;
    window.processFiles(files);
  });

  $dropZone.on("click", function () {
    $fileInput[0].click();
  });

  $fileInput.on("change", function () {
    var files = this.files;
    window.processFiles(files);
  });

  window.translateModal = $("#tamkinPlayerApp #translateModal");
  window.btntranslateASL = $(
    "#tamkinPlayerApp .translation-modal .btn-translate-asl"
  );
  window.btncloseASL = $("#tamkinPlayerApp .translation-modal .btn-close-asl");
  window.translationLoaderContainer = $(
    "#tamkinPlayerApp .translationLoaderContainer"
  );
  // window.translationPreviewContainer =$('#tamkinPlayerApp #translationModal #translationPreviewContainer')
  window.translationPlayerContainer = $(
    "#tamkinPlayerApp .translationPlayerContainer"
  );
  window.videoPreview = $(
    "#tamkinPlayerApp .translationPlayerContainer .video-preview"
  );
  window.characterPreview = $(
    "#tamkinPlayerApp .translationPlayerContainer .character-preview"
  );

  window.openASL = function (e) {
    let $btn = $(e.target);
    let isAudio = $btn.hasClass("asl-audio");
    let isVideo = $btn.hasClass("asl-video");
    btntranslateASL.hide();
    if (isAudio) {
      $btn.closest(".modal-body").css("height", "550px");
      $btn.closest(".character-preview-container").addClass("pb-0");
      $("#tamkinPlayerApp #audioTranslationModal .variable-height").css(
        "height",
        "65%"
      );
      $("#tamkinPlayerApp #audioTranslationModal .variable-height-text").css(
        "height",
        "35%"
      );
    } else if (isVideo) {
      videoPreview.css("width", "65%");
      characterPreview.css("width", "35%");
    }
    characterPreview.show();
    // start playing character
    let generatedTextEl = isAudio
      ? $("#tamkinPlayerApp .generated-text-audio")
      : $("#tamkinPlayerApp .generated-text-video");
    let generatedText = generatedTextEl.text().trim();
    window.getAdAnimate(generatedText);
    console.log(generatedText);
  };
  window.handleCloseASL = function (isAudio = false) {
    let $btn = $(
      `#tamkinPlayerApp .translation-modal .btn-close-asl.${
        isAudio ? "asl-audio" : "asl-video"
      }`
    );
    if (isAudio) {
      $btn.closest(".modal-body").css("height", "415px");
      $btn.closest(".character-preview-container").removeClass("pb-0");
      $("#tamkinPlayerApp #audioTranslationModal .variable-height").css(
        "height",
        "40%"
      );
      $("#tamkinPlayerApp #audioTranslationModal .variable-height-text").css(
        "height",
        "60%"
      );
    } else {
      // elseif (isVideo) {
      videoPreview.css("width", "100%");
    }
    btntranslateASL.show();
    characterPreview.hide();
  };
  window.closeASL = function (e) {
    let $btn = $(e.target);
    let isAudio = $btn.hasClass("asl-audio");
    let isVideo = $btn.hasClass("asl-video");
    window.handleCloseASL(isAudio);
  };
  window.playTranslation = function (isAudio = false) {
    window.handleCloseASL(isAudio);
    translationPlayerContainer.show();
    // if (!isAudio)
    // translationPreviewContainer.hide()
  };
  $btnUpload.on("click", function (e) {
    window.openTranslationModal();
  });
  window.openTranslationModal = function () {
    let isAudio = window.currentFiletype == "audio";
    let $modal = $("#tamkinPlayerApp #translationModal");
    if (isAudio) $modal = $("#tamkinPlayerApp #audioTranslationModal");

    // hide the first modal and show the translation modal
    translateModal.find('[data-bs-dismiss="modal"]').trigger("click");
    let translationModal = new bootstrap.Modal($modal[0], {});
    translationModal.show();

    translationLoaderContainer.show();
    // translationPreviewContainer.hide()
    translationPlayerContainer.hide();
    btntranslateASL.hide();
    setTimeout(() => {
      translationLoaderContainer.hide();
      if (isAudio) {
        window.playTranslation(isAudio);
      } else {
        // translationPreviewContainer.show()
        // translationPreviewContainer.on('click', function () {
        window.playTranslation(isAudio);
        // })
      }
    }, 20000);
  };

  btntranslateASL.on("click", window.openASL);
  btncloseASL.on("click", window.closeASL);
});
