<principle-component>
  
   <div id="tamkinPlayerApp">
  
    <main-player > </main-player>
  
   </div>


   <script >
   

    import main from './main.riot';
    import TaminSDK from './tamkin-sdk.riot';
    let version;

    const componentElement = document.querySelector('tamkin-player-sdk');
    if(componentElement)
    {
    version = componentElement.getAttribute('with-player');
    }
    export default { 
        
       components: {
        'main-player':main,
        'tamkin-sdk': TaminSDK
        
      },
     state : {
      with_player:version
     },
     onMounted(){
      console.log("ok");
     },
     
                 

                  
      onUpdated() { 
      },
      alert(e) {
        alert(1)
      }
    }
  </script>


</principle-component>