<accessibility-profiles>
    <div class="bg-white rounded-4 mb-2">
        <a class="btn px-3 py-2 d-flex align-items-center justify-content-between" data-bs-toggle="collapse" href="#collapseProfile">
            <div class="d-flex">
                <div class="rounded-circle tp-icon tp-bg-primary">
                    <img style="margin-bottom: 3px; width: 23px" src="./images/accessibility-profiles.svg" alt="">
                </div>
                <span class="px-2 font-16 font-w400 mb-0 align-content-center">Accessibility Profiles</span>
            </div>
            <div>
                <img class="accordion-arrow" src="./images/arrow-right.svg" alt="arrow">
            </div>
        </a>
    </div>
    <div id="collapseProfile" class="collapse rounded-4 bg-white py-3 px-2 mb-4" data-bs-parent="#tp-accordion">
        <div class="d-flex flex-wrap">
            <div each="{profile in state.profiles }" class="col-6 profile-item {profile.active ? 'active' : ''}" id="profile-{profile.name}" onclick="{() => activateProfile(profile)}">
                <div class="rounded-4 p-2 d-flex align-items-center">
                    <div class="rounded-circle tp-icon bg-white">
                        <img class="imggray" src="{profile.image}" alt="{profile.title}">
                    </div>
                    <span class="px-2 font-14 font-w400 mb-0 align-content-center">{profile.title}</span>
                </div>
            </div>
        </div>
    </div>
    <script>
         export default {
            triggerEvent(event, data=null) {
                window.EventBus.dispatch(event, data);
            },
            state: {
                profiles: [
                    {
                        name: 'adhd',
                        title: 'ADHD',
                        image: './images/profile-adhd.svg',
                        active: false,
                        features: [
                            {id: 'tp-feature-pause-animation', activeState: 1},
                            {id: 'tp-feature-saturation', activeState: 1},
                            {id: 'tp-feature-cursor', activeState: 3},
                        ]
                    },
                    {
                        name: 'blind',
                        title: 'Blind',
                        image: './images/profile-blind.svg',
                        active: false,
                        features: [
                            {id: 'tp-feature-screen-reader', activeState: 1},
                        ]
                    },
                    {
                        name: 'color-blind',
                        title: 'Color Blind',
                        image: './images/profile-color-blind.svg',
                        active: false,
                        features: [
                            {id: 'tp-feature-smart-contrast', activeState: 1},
                            {id: 'tp-feature-saturation', activeState: 2},
                        ]
                    },
                    {
                        name: 'dyslexia',
                        title: 'Dyslexia',
                        image: './images/profile-df.svg',
                        active: false,
                        features: [
                            {id: 'tp-feature-pause-animation', activeState: 1},
                            {id: 'tp-feature-dyslexia-friendly', activeState: 1},
                        ]
                    },
                    {
                        name: 'visually-impaired',
                        title: 'Visually Impaired',
                        image: './images/profile-visually-impaired.svg',
                        active: false,
                        features: [
                            {id: 'tp-feature-bigger-text', activeState: 1},
                            {id: 'tp-feature-pause-animation', activeState: 1},
                            {id: 'tp-feature-dyslexia-friendly', activeState: 2},
                            {id: 'tp-feature-cursor', activeState: 1},
                            {id: 'tp-feature-tooltip', activeState: 1},
                            {id: 'tp-feature-saturation', activeState: 2},
                        ]
                    },
                    {
                        name: 'cognitive',
                        title: 'Cognitive & Learning',
                        image: './images/profile-cognitive.svg',
                        active: false,
                        features: [
                            {id: 'tp-feature-smart-contrast', activeState: 1},
                            {id: 'tp-feature-bigger-text', activeState: 1},
                            {id: 'tp-feature-pause-animation', activeState: 1},
                            {id: 'tp-feature-cursor', activeState: 4},
                            {id: 'tp-feature-tooltip', activeState: 1},
                        ]
                    },
                    {
                        name: 'seizure',
                        title: 'Seizure & Epileptic',
                        image: './images/profile-seizure.svg',
                        active: false,
                        features: [
                            {id: 'tp-feature-pause-animation', activeState: 1},
                            {id: 'tp-feature-saturation', activeState: 1},
                        ]
                    },
                    {
                        name: 'motor-impaired',
                        title: 'Motor Impaired',
                        image: './images/profile-motor-impaired.svg',
                        active: false,
                        features: [
                            {id: 'tp-feature-pause-animation', activeState: 1},
                            {id: 'tp-feature-tooltip', activeState: 1},
                        ]
                    },
                ],
                features: [
                    {id: 'tp-feature-tooltip', activeStatesCount: 1},
                    {id: 'tp-feature-page-structure', activeStatesCount: 1},
                    {id: 'tp-feature-pause-animation', activeStatesCount: 1},
                    {id: 'tp-feature-dyslexia-friendly', activeStatesCount: 2},
                    {id: 'tp-feature-cursor', activeStatesCount: 4},
                    {id: 'tp-feature-saturation', activeStatesCount: 3},
                    {id: 'tp-feature-contrast', activeStatesCount: 3},
                    {id: 'tp-feature-text-spacing', activeStatesCount: 3},
                    {id: 'tp-feature-line-height', activeStatesCount: 3},
                    {id: 'tp-feature-text-align', activeStatesCount: 4},
                    {id: 'tp-feature-bigger-text', activeStatesCount: 4},
                    {id: 'tp-feature-highlight-links', activeStatesCount: 1},
                    {id: 'tp-feature-hide-images', activeStatesCount: 1},
                ],
            },
            activeProfile() {
                return this.state.profiles.find(profile => profile.active)
            },
            activateProfile(selectedProfile) {
                // deactivate others first if clicking a not active profile
                if(!selectedProfile.active)
                    this.state.profiles.map(profile => profile.active = false);
                // if clicking an active profile, toggle its state or if it's not active, activate it
                selectedProfile.active = !selectedProfile.active;
                
                // reset all first
                this.triggerEvent('resetAccessibility');

                // the same function of accessibility-controls.riot of reset
                if (selectedProfile.active) {
                    selectedProfile.features.forEach(feature => {
                        // trigger click feature.actiedState times
                        for (let i = 1; i <= feature.activeState; i++) {
                            $('#'+feature.id).trigger('click');
                        }
                    })
                }
                this.update()
            }
        }
    </script>

    <style>
        #collapseProfile .profile-item {
            cursor: pointer;
            margin-bottom: .4rem;
            padding-left: .2rem;
            padding-right: .2rem;
        }
        #collapseProfile .profile-item:nth-last-child(1), #collapseProfile .profile-item:nth-last-child(2) {
            margin-bottom: 0;
        }
        #collapseProfile .profile-item > div {
            background-color: #F7F7F7;
        }
        #collapseProfile .profile-item.active > div {
            background-color: var(--tp-primary);
            color: white;
        }
        #collapseProfile .profile-item .tp-icon {
            flex: 40%;
        }
        #collapseProfile .profile-item .tp-icon + span {
            flex: 99%;
        }
        #collapseProfile .profile-item > div {
            border: 2px solid transparent;
        }
        #collapseProfile .profile-item > div:hover {
            border: 2px solid var(--tp-primary);
        }
    </style>
</accessibility-profiles>