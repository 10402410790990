<accessibility>

    <div if="{!sidebarIsHidden()}">
        <div>
            <div class="accessibility-icon-container">
                <div class="tp-accessibility2">
                    <img src="./images/accessibility.svg">
                </div>
                <div class="tp-accessibility2-text">
                    Accessibility
                </div>
                <div class="tp-accessibility2-actions">
                    <img data-bs-toggle="modal" data-bs-target="#translateModal" class="access-video"
                        src="./images/access-video.svg" alt="">
                    <span class="mx-2"></span>
                    <img class="tp-accessibility2-sidebar-open access-pointer" src="./images/access-pointer.svg" alt="">
                </div>
            </div>
        </div>

        <mini-widget-accessibility-sidebar accessibility_settings="{ props.accessibility_settings }">
        </mini-widget-accessibility-sidebar>
        <div id="sidebody"></div>

        <translation></translation>

        <!-- page structure modal -->
        <div class="modal fade" id="pageStructureModal" tabindex="-1" aria-labelledby="pageStructureModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header tp-modal-header">
                        <div class="pt-3" id="translationModalModalLabel">
                            <p class=" font-18 font-w600">Page Structure</p>
                        </div>
                        <button type="button" data-bs-dismiss="modal" aria-label="Close">
                            <img src="./images/icon-close-sidebar.svg" alt="Close">
                        </button>
                    </div>
                    <div class="py-3">
                        <ul class="nav nav-tabs w-100 justify-content-around" id="pageStructureTabs" role="tablist">
                            <li class="nav-item col-4" role="presentation">
                                <button class="nav-link active" id="headings-tab" data-bs-toggle="tab"
                                    data-bs-target="#headings" type="button" role="tab" aria-controls="headings"
                                    aria-selected="true">Headings</button>
                            </li>
                            <li class="nav-item col-4" role="presentation">
                                <button class="nav-link" id="landmarks-tab" data-bs-toggle="tab"
                                    data-bs-target="#landmarks" type="button" role="tab" aria-controls="landmarks"
                                    aria-selected="false">Landmarks</button>
                            </li>
                            <li class="nav-item col-4" role="presentation">
                                <button class="nav-link" id="links-tab" data-bs-toggle="tab" data-bs-target="#links"
                                    type="button" role="tab" aria-controls="links" aria-selected="false">Links</button>
                            </li>
                        </ul>
                    </div>
                    <div class="modal-body tab-content" id="pageStructureTabContent">
                        <div class="tab-pane fade show active" id="headings" role="tabpanel"
                            aria-labelledby="headings-tab">
                            <ul class="list-unstyled page-structure-list" id="headingsList">
                                <!-- Headings will be dynamically inserted here -->
                            </ul>
                        </div>
                        <div class="tab-pane fade" id="landmarks" role="tabpanel" aria-labelledby="landmarks-tab">
                            <ul class="list-unstyled page-structure-list" id="landmarksList">
                                <!-- Landmarks will be dynamically inserted here -->
                            </ul>
                        </div>
                        <div class="tab-pane fade" id="links" role="tabpanel" aria-labelledby="links-tab">
                            <ul class="list-unstyled page-structure-list" id="linksList">
                                <!-- Links will be dynamically inserted here -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- end page structure modal -->


    </div>

    <!-- <script src="./player_files/accessibility/accessibility.js"></script> -->
    <script>
        // this puts svg images inside file3D/images. see webpack.config.js 
        const importAllImages = (requireContext) => requireContext.keys().map(requireContext);
        const images = importAllImages(require.context('/src/player_files/accessibility/img', false, /\.(png|jpe?g|svg)$/));

        import "@lottiefiles/lottie-player";
        import loader from "/src/player_files/images/h.svg"
        import handload from '/src/player_files/json/handload.json';
        import hearts from '/src/player_files/json/hearts.json';
        import loading from '/src/player_files/json/loading.json';
        import twohand from '/src/player_files/json/twohand.json';

        import "/src/player_files/accessibility/bootstrap.min.js";
        import "/src/player_files/accessibility/mini_widget/accessibility.js";
        import "/src/player_files/accessibility/mini_widget/accessibility.css";

        import TaminSDK from '/src/tamkin-sdk-only-player.riot';
        import AccessibilitySidebar from '/src/accessibility/mini_widget/accessibility-sidebar.riot';
        import Translation from '/src/accessibility/translation.riot';

        export default {
            components: {
                'tamkin-sdk-only-player': TaminSDK,
                'mini-widget-accessibility-sidebar': AccessibilitySidebar,
                'translation': Translation,
            },
            state: {
                // accessibility_settings: {},
            },
            /*
            add(e) {
                e.preventDefault()
        
                if (this.state.text) {
                  this.update({
                    items: [
                      ...this.state.items,
                      // add a new item
                      {title: this.state.text}
                    ],
                    text: ''
                  })
                }
            },
            toggle(item) {
                item.done = !item.done
                // trigger a component update
                this.update()
            },*/

            onMounted(props, state) {
            },
            onUpdated(props, state) {

            },
            sidebarIsHidden() {
                const hideUntil = localStorage.getItem('tpAccHideUntil');
                if (!hideUntil) {
                    return false;
                } else {
                    if (new Date() < new Date(hideUntil)) {
                        return true;
                    } else {
                        localStorage.removeItem('tpAccHideUntil');
                        return false;
                    }
                }
            },
        }
    </script>

    <style>
        /*
        some styles fot accessibility are in pricipalApp.riot because not working here any styles
        */
    </style>

</accessibility>